    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
}

.link {
    display: block;
    color: var(--prezly-footer-text-color);

    & + & {
        margin-left: $spacing-3;
    }
}

.icon {
    $size: 1.5rem;

    width: $size;
    height: $size;
}
