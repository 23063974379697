    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;
}

@mixin absolute-menu-position {
    @include border-radius-m;
    @include shadow-l;

    position: absolute;
    left: 0;
    transform-origin: top left;
    max-width: 280px;
    margin: $spacing-1 0 0;
    padding: $spacing-1;
    background: var(--prezly-background-color-secondary);
    border: 1px solid var(--prezly-border-color);

    @include mobile-only {
        max-width: 280px;
    }
}

.buttonWithMobileDisplay {
    @include mobile-only {
        width: 100%;
    }
}

.menu {
    list-style: none;
    overflow: hidden;

    &.withMobileDisplay {
        @include mobile-only {
            padding: 0;
            padding-bottom: $spacing-4;
            margin: 0;
            border-top: 1px solid var(--prezly-border-color);
        }

        @include tablet-up {
            @include absolute-menu-position;
        }
    }

    &:not(.withMobileDisplay) {
        @include absolute-menu-position;
    }
}

.container:last-child {
    .menu {
        left: auto;
        right: 0;
        transform-origin: top right;
    }
}

.caret {
    margin-left: $spacing-1;
    width: $spacing-2;
    height: $spacing-2;
}

.caretOpen {
    transform: rotate(180deg);
}

.transition {
    transition: opacity 0.2s ease, transform 0.2s ease;

    &.withMobileDisplay {
        @include mobile-only {
            transition: opacity 0.2s ease, max-height 0.2s ease;
        }
    }
}

.transitionOpenStart {
    opacity: 0;
    transform: scale(0.9);

    &.withMobileDisplay {
        @include mobile-only {
            transform: none;
            max-height: 0;
        }
    }
}

.transitionOpenFinish {
    opacity: 1;
    transform: scale(1);

    &.withMobileDisplay {
        @include mobile-only {
            transform: none;
            max-height: 500px;
        }
    }
}
