    @import "styles/variables";
    @import "styles/mixins";

.embargo {
    @include border-radius-s;
    @include heading-3;

    margin-bottom: $spacing-7;
    padding: $spacing-2;
    font-weight: $font-weight-semi-bold;
    text-align: center;
    background: $color-warning-bg;
    border: 1px solid $color-warning;
}
