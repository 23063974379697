    @import "styles/variables";
    @import "styles/mixins";

@mixin branding {
    color: var(--prezly-header-link-color);
    background: var(--prezly-placeholder-background-color);
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-semi-bold;
}

.imageContainer,
.image {
    height: 100%;
}

.image {
    @include branding;

    object-fit: cover;

    &.static {
        position: static !important;
    }
}

.imageContainer {
    position: relative;
    display: block;
}

.placeholder {
    @include paragraph;
    @include branding;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;

    &.static {
        aspect-ratio: 1.5;
    }
}

.placeholderLogo {
    object-fit: contain;
    max-width: 16rem;
    max-height: 4rem;
}
