    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $grid-gutter-tablet;

    &.desktop {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: $grid-gutter-desktop;
    }
}
