    @import "styles/variables";
    @import "styles/mixins";

.renderer {
    :global {
        /* stylelint-disable selector-class-pattern */
        .prezly-slate-heading--heading-1 {
            @include heading-1-xxl;

            margin: $spacing-1 0 $spacing-3;
            color: var(--prezly-text-color);
        }
        /* stylelint-enable selector-class-pattern */

        mark {
            @include highlighted-text;
        }
    }
}
