    @import "styles/variables";
    @import "styles/mixins";

@import "variables";
@import "mixins";

.link {
    @include text-label;
    @include link-primary;

    font-weight: $font-weight-semi-bold;
    text-decoration: none;
    cursor: pointer;
}
