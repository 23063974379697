    @import "styles/variables";
    @import "styles/mixins";

.title {
    margin-bottom: $spacing-5;
}

.filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: $spacing-2;
}

.badge {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: $spacing-2 $spacing-3;
    text-decoration: none;
    color: var(--prezly-text-color);
    border: 1px solid var(--prezly-border-color);
    border-radius: 100px;

    &.active {
        color: var(--prezly-accent-color-button-text);
        background-color: var(--prezly-accent-color);
        border-color: var(--prezly-accent-color);
    }
}
