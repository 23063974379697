    @import "styles/variables";
    @import "styles/mixins";

.link {
    margin-right: $spacing-6;
}

.icon {
    width: 1rem;
    height: 1rem;
    margin-left: $spacing-1;
}
