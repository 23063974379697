    @import "styles/variables";
    @import "styles/mixins";

.button {
    background-color: transparent;
    border: 0;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
}
