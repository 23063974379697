    @import "styles/variables";
    @import "styles/mixins";

.imageContainer {
    display: block;
}

.placeholder {
    background-color: var(--prezly-accent-color);
}

.image, .placeholder {
    @include border-radius-s;

    object-fit: cover;
}

.imageContainer, .placeholder {
    @include border-radius-s;

    position: relative;
    height: 150px;

    &::after {
        @include border-radius-s;

        background: black;
        opacity: .5;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
    }
}
